import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 * Localize `<time>` element w/ Day.js library
 * @param {Element} el  The `<time>` element w/ `datetime` & other data attributes
 */
const localizeDatetimeElem = (el) => {
  const datetimeAttr = el.getAttribute("datetime");
  const localized = el.hasAttribute("data-dayjs-localize")
    ? el.getAttribute("data-dayjs-localize") === "true"
    : true;
  const dateFormat = el.hasAttribute("data-dayjs-date-display-format")
    ? el.getAttribute("data-dayjs-date-display-format")
    : "LL";
  const timeFormat = el.hasAttribute("data-dayjs-time-display-format")
    ? el.getAttribute("data-dayjs-time-display-format")
    : "LT" + (!localized ? " [UTC]" : "");
  const outputDate = el.hasAttribute("data-dayjs-output-date")
    ? el.getAttribute("data-dayjs-output-date") === "true"
    : true;
  const outputTime = el.hasAttribute("data-dayjs-output-time")
    ? el.getAttribute("data-dayjs-output-time") === "true"
    : true;

  // init DayJS & convert to UTC?
  const dayjsDatetime = !localized
    ? dayjs(datetimeAttr).utc()
    : dayjs(datetimeAttr);

  // prepare inner datetime HTML
  const dateHtml = `<span class="date">${dayjsDatetime.format(
    dateFormat
  )}</span>`;
  const timeHtml = `<span class="time">${dayjsDatetime.format(
    timeFormat
  )}</span>`;

  // add HTML to datetime element
  el.innerHTML =
    !el.hasAttribute("data-dayjs-output-date") || outputDate ? dateHtml : "";
  el.innerHTML += outputDate && outputTime ? " " : "";
  el.innerHTML +=
    !el.hasAttribute("data-dayjs-output-time") || outputTime ? timeHtml : "";
};

export default localizeDatetimeElem;
