/**
 * Get the ancestors of an element in the current set of matched elements, optionally filtered by a selector.
 *
 * Searches through the ancestors of one or more elements in the DOM tree and constructs a new object from the matching elements ordered from immediate parent on up; the elements are returned in order from the closest parent to the outer ones.
 *
 * @param {Element} elem  One (or more) element(s) at which to begin traversal
 * @param {String} selector  A string containing a selector expression to match elements against
 * @returns {NodeList}  An object of pertinent nodes
 */

const parents = (elem, selector) => {
  const parents = [];
  while ((elem = elem.parentNode) && elem !== document) {
    if (!selector || elem.matches(selector)) parents.push(elem);
  }
  return parents;
};

export default parents;
