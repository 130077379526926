/**
 * Filter list of checkboxes by checked
 * @param {Array} checkboxes  An array checkbox inputs
 * @return {Array}  Array of checked checkboxes
 */

const filterByChecked = (checkboxes) => {
  const checked = Array.from(checkboxes) // Convert checkboxes to an array to use filter
    .filter((i) => i.checked); // Use Array.filter to remove unchecked checkboxes
  return checked;
};

export default filterByChecked;
