/* eslint-disable no-unused-vars */
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as dayjs from "dayjs";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as Modernizr from "modernizr";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import Collapse from "bootstrap/js/dist/collapse";
import debounce from "./modules/debounce";
import Dropdown from "bootstrap/js/dist/dropdown";
import filterByChecked from "./modules/filterByChecked";
import localizeDatetimeElem from "./modules/localizeDatetimeElem";
import Modal from "bootstrap/js/dist/modal";
import parents from "./modules/parents";
import ready from "./modules/onReady";
import timeoutPromise from "./modules/timeoutPromise";

// extend DayJS functionality with these plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

/**
 * Add Modernizr tests for 'AVIF' & 'JXL' image formats
 */
let avifImage = new Image();
avifImage.onload = avifImage.onerror = () => {
  Modernizr.addTest("avif", avifImage.height);
};
avifImage.src =
  "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";

let jxlImage = new Image();
jxlImage.onload = jxlImage.onerror = () => {
  Modernizr.addTest("jxl", avifImage.height);
};
jxlImage.src =
  "data:image/jxl;base64,/woIELASCAgQAFwASxLFgkWAHL0xqnCBCV0qDp901Te/5QM=";

ready(() => {
  const webinarElems = document.querySelectorAll(
    ".sessions__list .session:not(.stay-updated)"
  );
  const registrationButtons = document.querySelectorAll(
    ".session__registration-btn"
  );
  const registrationPanel = document.querySelector(".sessions__registration");
  const registrationForm = document.getElementById("registration-form");
  const submitBtn = registrationForm.querySelector("#submit");
  const registrationFormStatusMessage = document.getElementById(
    "registration-form-status"
  );
  const webinarDropdownButton = document.getElementById("webinarDropdown");
  const webinarDropdownOptions = registrationForm.querySelectorAll(
    ".dropdown--webinars .dropdown-item"
  );
  const webinarDropdownCheckboxes = registrationForm.querySelectorAll(
    '[name^="webinars["]'
  );
  let now,
    hasActiveWebinars = false,
    selectedWebinars = [];
  const registrationSuccessModal = Modal.getOrCreateInstance(
    document.getElementById("registration-success-modal"),
    { backdrop: true }
  );
  // const timezoneAbbreviationMap = {
  //   "Central European Standard Time": "CET",
  //   "Central European Summer Time": "CEST",
  //   "Japan Standard Time": "JST",
  //   "Eastern Standard Time": "EST",
  //   "Eastern Daylight Time": "EDT",
  // };

  // if any active webinar elements exist...
  if (webinarElems.length > 0) {
    // instantiate a new DayJS object w/ current time
    now = dayjs();
  }

  //check if HCP modal has been shown
  const hcpModal = document.querySelector(".hcp-modal");
  const hcpModalShown = sessionStorage.getItem("hcpModalShown");
  if (!hcpModalShown) {
    hcpModal.classList.add("show");
    hcpModal.querySelector("#close-hcp-popup").addEventListener("click", () => {
      hcpModal.classList.remove("show");
      sessionStorage.setItem("hcpModalShown", "true");
    });
  }

  /**
   * Localize datetimes
   *
   * Converts various datetime elements to locale
   */
  const datetimeElems = document.querySelectorAll(
    'time[datetime][data-dayjs-output-date="true"],time[datetime][data-dayjs-output-time="true"]'
  );
  if (datetimeElems.length > 0) {
    datetimeElems.forEach((el) => localizeDatetimeElem(el));
  }

  // adds an event listener to the registration form success modal to auto-close after 5 seconds
  registrationSuccessModal["_element"].addEventListener(
    "shown.bs.modal",
    () => {
      const modalStatus = registrationSuccessModal["_element"].querySelector(
        ".modal-body .status"
      );
      let timeleft = 5;
      setTimeout(() => {
        const downloadTimer = setInterval(() => {
          if (timeleft <= 0) {
            clearInterval(downloadTimer);

            // reset registration form upon timeout
            resetRegistrationForm();

            registrationSuccessModal.hide();
            modalStatus.innerHTML = "Will automatically close in 5 seconds...";
          } else {
            modalStatus.innerHTML =
              "Will automatically close in " + timeleft + " seconds...";
          }
          timeleft -= 1;
        }, 1000);
      }, 5000);
    }
  );

  // reset registration form upon clicking the close icon
  registrationSuccessModal["_element"]
    .querySelector(".btn-close")
    .addEventListener("click", resetRegistrationForm);

  // update previous session(s) accordion widths
  const previousSessions = document.querySelectorAll(
    "#previousSessions .session"
  );

  function updatePreviousSessionsAccordionItemWidths(elems) {
    if (elems.length > 0) {
      elems.forEach((el, i) => {
        const dateEl = el.querySelector(".accordion-button .date");
        const infoEl = el.querySelector(".accordion-button .info");

        // set maxWidth of .info element (so div does not overflow container)
        infoEl.style.maxWidth = `calc(100% - (${dateEl.offsetWidth}px + 30px + 1.5rem)`;
      });
    }
  }
  updatePreviousSessionsAccordionItemWidths(previousSessions);

  /**
   * Active webinars
   *
   * Controls active webinars in `#activeSession` div.
   */
  if (webinarElems.length > 0) {
    webinarElems.forEach((el, i) => {
      const datetimeEl = el.querySelector(".session__datetime");
      const datetime = datetimeEl.getAttribute("datetime");
      const localDatetime = dayjs(datetime);
      // const localDatetime = dayjs(datetime).tz('Asia/Tokyo'); // ...for testing

      outputLocalDatetime(el, datetimeEl, localDatetime, now);

      // check if event has expired
      //  ...can replace `localDatetime.subtract(7, 'year')` for testing purposes
      if (now > localDatetime) {
        // el.style.display = 'none';
        el.classList.add("closed");
        adjustWebinarStatus(el, "closed");
      }
    });
  }

  function outputLocalDatetime(sessionEl, datetimeEl, eventTime, currentTime) {
    const localDate = eventTime.format("dddd, LL");
    // let localTime = eventTime.format('LT zzz');
    let localTime = eventTime.format('LT [<abbr title="]zzz[">]z[</abbr>]');

    // if 72 hrs. before event time, close event
    // if (currentTime > eventTime.subtract(72, 'hour')) {
    //   adjustWebinarStatus(sessionEl, 'closed');
    // }

    // wrap meridiem (so we can style it differently)
    localTime = localTime.replace(/ ?([AP]M)/i, "<small>$1</small>");

    // update date and time (to local date/time)
    const timeEl = datetimeEl.querySelector(".time");
    datetimeEl.querySelector(".date").innerHTML = localDate;
    timeEl.innerHTML = localTime + "<sup>*</sup>";

    // replace timezone in disclaimer
    sessionEl.querySelector(".session__disclaimer .tz").innerText =
      eventTime.format("zzz");
  }

  function adjustWebinarStatus(webinarEl, type = "closed") {
    const btn = webinarEl.querySelector("button.session__registration-btn");

    // if (type !== "closed") {
    //   let type = "closed";
    // }

    // add 'closed' class
    webinarEl.classList.add(type);

    // set event to CLOSED
    webinarEl.querySelector("h5").innerText = webinarEl
      .querySelector("h5")
      .getAttribute(`data-label-${type}`);

    // adjust registration button
    btn.setAttribute("aria-label", btn.getAttribute(`data-label-aria-${type}`));
    btn.disabled = true;
    btn.innerText = btn.getAttribute(`data-label-${type}`);
  }

  if (registrationButtons.length > 0) {
    registrationButtons.forEach((el, i) => {
      if (el.hasAttribute("data-session-id")) {
        const sessionId = el.getAttribute("data-session-id");

        el.addEventListener("click", (e) => {
          e.preventDefault();

          if (!el.classList.contains("selected")) {
            // 1st - scroll down to Registration Form
            scrollIntoView(registrationPanel);

            // 2nd - add 'selected' class to button (adds additional styling)
            setTimeout(() => {
              updateWebinarRegisterButton(el);
            }, 500);

            // 3rd (optional) - display webinar dropdown field in registration form
            // setTimeout(() => webinarsDropdown.show(), 500);

            // 4th - pre-check webinar in dropdown
            document.getElementById(sessionId).checked = true;
          } else {
            // 1st - remove 'selected' class from button
            updateWebinarRegisterButton(el);

            // 2nd - uncheck webinar in dropdown
            document.getElementById(sessionId).checked = false;
          }

          selectedWebinars = filterByChecked(webinarDropdownCheckboxes);
          updateWebinarDropdown(selectedWebinars);
        });
      }
    });
  }

  // adds event listener to 'get notified' button in stay-updated notification tile
  const getNotifiedBtn = document.querySelector(
    ".session__registration-btn.stay-updated"
  );
  if (getNotifiedBtn) {
    getNotifiedBtn.addEventListener("click", (e) => {
      e.preventDefault();

      // scroll down to Registration Form
      scrollIntoView(registrationPanel);
    });
  }

  /**
   * Webinar dropdown options
   *
   * Controls "Webinar Selection" dropdown field in the registration form.
   */
  if (webinarDropdownOptions.length > 0) {
    webinarDropdownOptions.forEach((el) => {
      const checkbox = el.querySelector('[name^="webinars["]');
      const timeEl = el.querySelector("time");
      const localDatetime = dayjs(timeEl.getAttribute("datetime"));
      // let localizedDatetimeStr = localDatetime.format('LLL zzz');
      let localizedDatetimeStr = localDatetime.format("LLL z");

      // if 72 hrs. before, or ∞ after event time, hide option
      // if (now > localDatetime.subtract(72, 'hour')) {
      //   el.classList.add('hidden');
      // }

      // check if event has expired
      if (now > localDatetime) {
        // is expired event
        el.classList.add("hidden");
      }

      checkbox.addEventListener("change", () => {
        selectedWebinars = filterByChecked(webinarDropdownCheckboxes);
        updateWebinarDropdown(selectedWebinars);

        // mark registration button next to respective webinar as 'Selected'
        updateWebinarRegisterButton(
          document.querySelector('[data-session-id="' + checkbox.id + '"]')
        );

        if (registrationForm.classList.contains("was-validated")) {
          if (selectedWebinars.length > 0) {
            webinarDropdownButton.classList.remove("is-invalid");
          } else {
            webinarDropdownButton.classList.add("is-invalid");
          }
        }
      });

      timeEl.innerHTML = localizedDatetimeStr;

      // check if at least one webinar is active and if so, update variable
      if (!el.classList.contains("hidden")) hasActiveWebinars = true;
    });
  }

  // display "Webinar Selection" dropdown only if one or more webinars are active
  webinarDropdownButton.style.display = !hasActiveWebinars ? "none" : "block";

  /**
   * Registration form
   *
   * The event registration form.
   */
  registrationForm.addEventListener(
    "submit",
    async (event) => {
      let failed = false;

      event.preventDefault();
      event.stopPropagation();

      // clear form status messaging
      registrationFormStatusMessage.className = "";
      registrationFormStatusMessage.innerHTML = "&nbsp;";

      // manually validate if any webinars are selected
      if (hasActiveWebinars) {
        const dd = parents(
          webinarDropdownCheckboxes[0],
          ".dropdown--webinars"
        )[0];

        if (selectedWebinars.length == 0 && hasActiveWebinars) {
          webinarDropdownCheckboxes.forEach((el) => {
            el.required = true;
          });
          dd.classList.add("is-invalid");
          dd.classList.remove("is-valid");
          failed = true;
        } else {
          webinarDropdownCheckboxes.forEach((el) => {
            el.required = false;
          });
          dd.classList.remove("is-invalid");
          dd.classList.add("is-valid");
        }
      }

      // check if any form field constraints are NOT satisfied (incl. honeypot)
      if (
        registrationForm.checkValidity() === false ||
        document.getElementById("faxNumber").value.length > 1
      ) {
        failed = true;

        // update form status messaging
        displayFormMessageWithTimeout(
          registrationFormStatusMessage,
          window.registrationMessages.invalid,
          "invalid",
          5000
        );
      }

      // apply Bootstrap validation class (displays `:valid`, `:invalid`, `.is-valid`, & `.is-invalid` styles)
      registrationForm.classList.add("was-validated");

      if (failed == true) {
        return;
      }

      // prepare form object
      let body = {
        name:
          registrationForm.querySelector("#firstName").value +
          " " +
          registrationForm.querySelector("#lastName").value,
        email: registrationForm.querySelector("#email").value,
        hospital: registrationForm.querySelector("#affiliatedHospital").value,
        jobTitle: registrationForm.querySelector("#jobTitle").value,
        specialty: registrationForm.querySelector("#specialty").value,
        country: registrationForm.querySelector("#country").value,
        webinars: hasActiveWebinars
          ? selectedWebinars.map((i) => i.value)
          : "No active webinars.",
        environment: registrationForm.querySelector("#environment").value,
        environmentUrl: window.location.hostname,
        _email: {
          from: "CAR-T Cell Therapy Registration Website",
          subject: "There is a new CAR-T Cell Therapy registration!",
          template: {
            title: false,
            footer: false,
          },
        },
      };

      if (ENV !== "development") {
        // add the Google reCAPTCHA v2 response to the submission data
        // ...
        // reCAPTCHA site key: 6LcOBD4oAAAAANUwPlAtZwpzkBA2aSTTHemqqBHg
        // reCAPTCHA secret key: 6LcOBD4oAAAAAPlZYtbmBp2AxMtWqXbc0i_hXTbz
        body["g-recaptcha-response"] = registrationForm.querySelector(
          '[name="g-recaptcha-response"]'
        ).value;
      }

      // output form data object in console (for testing in local dev & staging)
      if (ENV !== "production") {
        console.log(body);
      }

      // disable submit button (until response received) to prevent subsequent clicks
      submitBtn.disabled = true;
      submitBtn.classList.add("submitting");
      submitBtn.innerText = "Submitting...";
      submitBtn.setAttribute("aria-label", "Submitting form...");

      // const formEndpoint = "/register"; // local test submission via `browserSync()` middleware route
      // const formEndpoint = 'https://submit-form.com/echo'; // for testing via FormSpark
      const formEndpoint = "https://submit-form.com/1J2il2sO"; // live form endpoint

      timeoutPromise(
        5000,
        fetch(formEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(body),
        })
      )
        .then((response) => {
          // process response

          // response was returned, so re-enable button
          submitBtn.disabled = false;
          submitBtn.innerText = "Register";
          // enableSubmitBtn();

          if (!response.ok) {
            if (ENV !== "production") {
              console.log(`An error has occured: ${response.status}`);
            }

            enableSubmitBtn();
            // submitBtn.classList.add("error");
            submitBtn.setAttribute(
              "aria-label",
              "There was an error with form submission. Please try again."
            );

            // update form status messaging
            displayFormMessageWithTimeout(
              registrationFormStatusMessage,
              window.registrationMessages.error,
              "error"
            );
          } else {
            if (ENV !== "production") {
              console.log("Submission successful!", response);
            }

            submitBtn.classList.add("submitted");
            submitBtn.setAttribute(
              "aria-label",
              "Form submitted successfully!"
            );

            // show modal `success`
            registrationSuccessModal.show();

            // reset registration form fields (since submission was successful)
            // TODO: Uncomment below function call if wanting form fields to clear upon successful submission
            // resetRegistrationForm();
          }

          // remove button class
          submitBtn.classList.remove("submitting");
        })
        .catch((error) => {
          // might be a timeout error
          if (ENV !== "production") {
            console.log("fetch possibly canceled!", error);
          }

          enableSubmitBtn();

          // update form status messaging
          displayFormMessageWithTimeout(
            registrationFormStatusMessage,
            window.registrationMessages.timeout,
            "timeout",
            10000
          );
        });
    },
    false
  );

  function disableSubmitBtn() {
    submitBtn.disabled = true;
    submitBtn.classList.add("submitting");
    submitBtn.innerText = "Submitting...";
    submitBtn.setAttribute("aria-label", "Submitting form...");
  }

  function enableSubmitBtn() {
    submitBtn.disabled = false;
    submitBtn.classList.remove("submitting");
    submitBtn.innerText = "Register";
    submitBtn.removeAttribute("aria-label");
  }

  function displayFormMessageWithTimeout(
    elem,
    message,
    className,
    timeout = null
  ) {
    elem.classList.add(className);
    elem.innerHTML = message;

    if (typeof timeout == "number" && timeout > 0) {
      setTimeout(() => {
        elem.style.opacity = "0";
        elem.addEventListener("transitionend", () => {
          hideFormMessage(elem);
          elem.style.opacity = "1";
        });
      }, timeout);
    }
  }

  function hideFormMessage(elem) {
    elem.className = "";
    elem.innerHTML = "&nbsp;";
  }

  function resetRegistrationForm() {
    // remove Bootstrap validation styling
    registrationForm.classList.remove("was-validated");

    // clear all form fields after submitting
    registrationForm.reset();

    // reset dropdown (have to manually uncheck the webinars dropdown fields) & styling
    if (hasActiveWebinars) {
      webinarDropdownCheckboxes.forEach(
        (checkboxInput) => (checkboxInput.checked = false)
      );
      parents(
        webinarDropdownCheckboxes[0],
        ".dropdown--webinars"
      )[0].classList.remove("is-valid");
      selectedWebinars.length = 0;
      resetWebinarDropdown();
    }

    // reset webinar 'Register' buttons
    resetWebinarRegistrationButtons();

    // clear form status messaging
    registrationFormStatusMessage.className = "";
    registrationFormStatusMessage.innerHTML = "&nbsp;";
  }

  // debounce window resize event
  const onResize = debounce(() => {
    updatePreviousSessionsAccordionItemWidths(previousSessions);
  }, 500); // Maximum run of once per 500 milliseconds
  window.addEventListener("resize", onResize, false);

  function scrollIntoView(el, behaviorProp = "smooth") {
    if (el) {
      el.scrollIntoView({ behavior: behaviorProp });
    }
  }

  function updateWebinarRegisterButton(buttonEl) {
    if (!buttonEl.classList.contains("selected")) {
      buttonEl.innerHTML = "<em>Selected</em>";
      buttonEl.classList.add("selected");
      buttonEl.title = "Selected - Click to unselect this speaker session";
    } else {
      buttonEl.innerHTML = "Register";
      buttonEl.classList.remove("selected");
      buttonEl.title = "";
    }
  }

  function resetWebinarRegistrationButtons() {
    registrationButtons.forEach((button) => {
      button.innerHTML = "Register";
      button.classList.remove("selected");
      button.tabIndex = 1;
    });
  }

  function updateWebinarDropdown(selectedWebinars = []) {
    const webinarDropdownEl = webinarDropdownButton.parentNode;
    webinarDropdownButton.querySelector(".dropdown-label").innerText =
      "Sessions selected:";
    if (selectedWebinars.length) {
      webinarDropdownEl.classList.add("initialized");
      webinarDropdownButton.classList.add("options-selected");
      webinarDropdownButton.querySelector(".dropdown-content").innerHTML =
        "<ul>" +
        selectedWebinars
          .map(
            (i) =>
              '<li><time datetime="' +
              i.parentNode.querySelector("time").getAttribute("datetime") +
              '">' +
              i.parentNode.querySelector("time").innerText +
              ": </time><p>" +
              i.parentNode.querySelector("h6").innerText +
              "</p></li>"
          )
          .join("") +
        "</ul>";
      webinarDropdownEl.classList.remove("is-invalid");
    } else {
      webinarDropdownButton.classList.remove("options-selected");
      webinarDropdownButton.querySelector(".dropdown-content").innerHTML =
        "<p>Please select one or more sessions.</p>";
    }
  }

  function resetWebinarDropdown() {
    webinarDropdownButton.parentNode.classList.remove("initialized");
    webinarDropdownButton.querySelector(".dropdown-label").innerHTML =
      'Session Selection<span class="req" aria-hidden="true">*</span>';
    webinarDropdownButton.classList.remove("options-selected");
    webinarDropdownButton.querySelector(".dropdown-content").innerHTML = "";
  }
});
