/**
 * A function to execute when the DOM is fully loaded
 * @param {Function} callback  The function to invoke on DOM ready
 */

const onReady = (callback) => {
  if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
  ) {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
};

export default onReady;
