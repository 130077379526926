/**
 * Wait until a certain amount of time has passed before invoking the passed
 * callback function
 * @param {Function} callback  The function to call
 * @param {Number} wait  A duration in milliseconds
 */

const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export default debounce;
